const data = [

  {
    date: "08-09-2022",
    name: "Circular - Half Yearly Examination, Class I & II, 2022-2023",
    link:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-CIRCULAR-I-&-II(2022-23).pdf",
  },
  {
    date: "28-02-2022",
    name: "Circular - Annual Exam Result and Academic Session 2022-23",
    link:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/001-Annual-Exam-Result-and-Academic-Session-2022-23.pdf",
  },
  {
    date: "10-09-2021",
    name: "School Reopening for Class IX - XII",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/School-Reopening.pdf",
  },
  {
    date: "31-03-2021",
    name: "School Circular for Academic Session 2021-22",
    link:
      "https://chinmayavvdelhi.ac.in/assets/pdfs/Circular-Academic-Session-2021-22.pdf",
  },
  // {
  //   date: "11-02-2021",
  //   name:
  //     "Criteria for Pre-School (Nursery) Admission for the Academic Session 2021-22",
  //   link:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/CHINMAYA-VIDYALAYA-Nursery-Admission-Criteria-2021.pdf",
  // },
];

export default data;
